import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import Navigation from '../Navigation/Navigation';
import DataContextProvider from '../../contexts/DataContext';
import { DialogContext, RenderDialog } from '../../contexts/DialogContext';
import PlaylistList from '../Playlist/PlaylistList';
import PlaylistCreate from '../Playlist/PlaylistCreate';
import PlaylistEdit from '../Playlist/PlaylistEdit';
import MediaList from '../Media/MediaList';
import ChannelsList from '../Channels/ChannelsList';
import Scheduler from 'components/Scheduler/Scheduler';

const Authenticated: React.FC = () => {
  const { component } = useContext(DialogContext);

  return (
    <DataContextProvider>
      <Router>
        <Box
          component={Paper}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Navigation />
          <ChannelsList />

          <Box display="flex" flex={1}>
            <Switch>
              <Route path="/playlists/edit/:id">
                <PlaylistEdit copy={false} key="edit" />
              </Route>
              <Route path="/playlists/copy/:id">
                <PlaylistEdit copy={true} key="copy" />
              </Route>
              <Route path="/playlists/create">
                <PlaylistCreate />
              </Route>
              <Route path="/medias">
                <MediaList />
              </Route>
              <Route path="/">
                <PlaylistList />
              </Route>
            </Switch>
          </Box>
        </Box>

        <Switch>
          <Route path="/" exact>
            <Scheduler showDroppableText={true} />
          </Route>
          <Route path="/playlists/edit/:id">
            <Scheduler showDroppableText={false} />
          </Route>
        </Switch>
        <RenderDialog>{component}</RenderDialog>
      </Router>
    </DataContextProvider>
  );
};

export default Authenticated;
