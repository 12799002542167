import {
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  InputBase,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core';
import React, { useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    position: 'absolute',
    marginLeft: 5,
    marginTop: -5
  },
  inputBaseRoot: {
    background: 'none'
  },
  inputBaseField: {
    backgroundColor: theme.palette.primary.dark,
    paddingBottom: 0,
    paddingTop: theme.spacing(0.1)
  },
  button: {
    lineHeight: 1.65,
    paddingBottom: 0,
    paddingTop: 0
  }
}));

interface EditablePropertyProps {
  labelText: string;
  labelValue: string;
  itemValue: string;
  isActive: boolean;
  onSave: (value: string) => Promise<boolean>;
}

const EditableProperty: React.FC<EditablePropertyProps> = ({
  labelText,
  labelValue,
  itemValue,
  isActive,
  onSave
}) => {
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const handleCloseClick = (): void => setIsInEditMode(false);
  const classes = useStyles();

  return (
    <ListItemText
      primary={
        <>
          {labelText}
          {isActive && (
            <Tooltip title="Rediger">
              <IconButton
                size="small"
                onClick={() => setIsInEditMode(!isInEditMode)}
                className={classes.icon}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      }
      secondary={
        isInEditMode ? (
          <TextField
            itemValue={itemValue}
            onSave={onSave}
            onClose={handleCloseClick}
          />
        ) : (
          labelValue
        )
      }
      secondaryTypographyProps={{ component: 'div' }}
    />
  );
};

export default EditableProperty;

interface TextFieldProps {
  itemValue: string;
  onSave: (value: string) => Promise<boolean>;
  onClose: () => void;
}

const TextField: React.FC<TextFieldProps> = ({
  itemValue,
  onSave,
  onClose
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<string>((itemValue ?? '').toString());
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSaveClick = async () => {
    setIsSaving(true);

    const result = await onSave(value);
    if (result) {
      onClose();
    }

    setIsSaving(false);
  };

  return (
    <InputBase
      classes={{ root: classes.inputBaseRoot, input: classes.inputBaseField }}
      autoFocus
      fullWidth
      required
      value={value}
      onChange={handleTitleChange}
      endAdornment={
        <InputAdornment position="end">
          <ButtonGroup>
            <Button className={classes.button} size="small" onClick={onClose}>
              Annuller
            </Button>
            <Button
              className={classes.button}
              disabled={isSaving}
              size="small"
              onClick={handleSaveClick}
            >
              Gem
            </Button>
          </ButtonGroup>
        </InputAdornment>
      }
    />
  );
};
