import React from 'react';
import { Box, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import MediaCard from '../../Media/MediaCard';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { MediaItem } from 'interfaces';

const SelectedMedias: React.FC = () => {
  const sensors = useSensors(useSensor(PointerSensor));
  const [field, meta, helpers] = useField<MediaItem[]>({ name: 'medias' });
  const medias = field?.value?.map((x) => x) || [];
  const mediaIds = field?.value?.map((x) => x.uid) || [];

  if (meta.touched && field?.value?.length === 0) {
    return <FormHelperText error>{meta.error}</FormHelperText>;
  }

  // prettier-ignore
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = medias.findIndex((x) => x.uid === active.id);
      const newIndex = medias.findIndex((x) => x.uid === over?.id);
      const newValue = arrayMove(medias, oldIndex, newIndex);
      helpers.setValue(newValue);
    }
  }

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="flex-start"
      flexWrap="wrap"
    >
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={mediaIds}>
          {medias.map((media, index) => {
            const position = index + 1 + '/' + medias.length;

            return (
              <MediaCard
                item={{ ...media }}
                removeButton
                movableButton
                showPosition
                position={position}
                style={{
                  marginRight: 8,
                  marginBottom: 8,
                  width: 'calc(25% - 8px)'
                }}
                key={media.uid}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </Box>
  );
};

export default SelectedMedias;
