import { createTheme as createMuiTheme, Theme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const palette = createPalette({
  type: 'dark',
  background: {
    default: '#16143a',
    paper: '#16143a'
  },
  primary: {
    50: '#ede7f6',
    100: '#d1c4e9',
    200: '#b39ddb',
    300: '#29265f',
    400: '#7e57c2',
    500: '#312e6b',
    600: '#5e35b1',
    700: '#25204a',
    800: '#4527a0',
    900: '#311b92',
    A100: '#b388ff',
    A200: '#7c4dff',
    A400: '#651fff',
    A700: '#6200ea'
  }
});

const breakpoints = createBreakpoints({});

// export const backgroundColors = {
//   navigation: '#312e6b',
//   textfield: '#25204a'
// };

export const createTheme = (): Theme =>
  createMuiTheme({
    palette: palette,
    typography: {
      fontSize: 14,

      h1: {
        fontSize: 24,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto'
      },
      h2: {
        fontSize: 20,
        overflowWrap: 'break-word',
        wordWrap: 'break-word',
        hyphens: 'auto'
      },
      h3: {
        fontSize: 18
      },
      body1: {
        fontSize: 14
      },
      body2: {
        fontSize: 12
      }
    },
    overrides: {
      MuiTabs: {
        root: {
          flex: 1
        },
        scrollButtons: {
          backgroundColor: '#233044'
        }
      },
      MuiCard: {
        root: {
          backgroundColor: '#fff'
        }
      },
      MuiCardContent: {
        root: {
          color: '#1a1a1a'
        }
      },
      MuiListItemText: {
        primary: {
          color: palette.text.secondary,
          opacity: 0.7,
          fontSize: 12
        },
        secondary: {
          color: palette.text.primary,
          fontSize: 14,
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
          hyphens: 'auto'
        }
      },
      MuiSelect: {
        select: {
          padding: '7px 0 6px 0',

          '&:focus': {
            backgroundColor: 'inherit'
          }
        }
      },
      MuiFormLabel: {
        root: {
          color: palette.text.secondary,

          '&.Mui-focused': {
            color: palette.text.secondary
          }
        }
      },
      MuiTab: {
        root: {
          fontWeight: 400,
          textTransform: 'none',

          [breakpoints.up('md')]: {
            minWidth: 100
          }
        },
        textColorInherit: {
          opacity: 0.5
        }
      },
      MuiTableRow: {
        hover: {}
      },
      MuiPickersDay: {
        current: {
          color: palette.secondary.main
        }
      },
      MuiToolbar: {
        gutters: {
          [breakpoints.up('sm')]: {
            paddingLeft: 16
          }
        }
      },
      MuiButton: {
        root: {
          fontWeight: 400,
          textTransform: 'none'
        },
        textSizeSmall: {
          fontSize: 12
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, .85)'
        }
      }
    },
    props: {
      MuiCard: {
        elevation: 0
      },
      MuiPaper: {
        elevation: 0
      },
      MuiButton: {
        disableElevation: true
      },
      MuiButtonGroup: {
        disableElevation: true
      }
    }
  });
