import { xml2json } from '../lib/xml-parser';
import { useFetcher } from './useFetcher';

export const useGetLocalization = () => {
  const { fetcher } = useFetcher();

  const getLocalization = async (): Promise<any> => {
    const result = await fetcher(`localized_data.xml`);
    return new Promise((resolve) => {
      const json = xml2json(result, 'text');
      const languages = json?.page.languages.language || [];
      const phrases = json?.page.phrase || [];
      resolve({ languages, phrases });
    });
  };
  return { getLocalization };
};
