import { objectToArray } from 'helpers/arrayHelper';
import { Channel } from '../interfaces';
import { xml2json } from '../lib/xml-parser';
import { useFetcher } from './useFetcher';

export const useGetChannels = () => {
  const { fetcher } = useFetcher();

  const getChannels = async (): Promise<Channel[]> => {
    const result = await fetcher('get_channels.php');

    return new Promise((resolve) => {
      const json = xml2json(result, 'name');
      let channels = json?.rootnode.group || [];

      // The xml2json parser will return either an object or an array of objects.
      // Let's make it easy and put a single object into an array.
      channels = objectToArray(channels);

      resolve(channels);
    });
  };
  return { getChannels };
};
