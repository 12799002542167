import React, { useContext } from 'react';
import {
  Button,
  Box,
  Menu,
  MenuItem,
  makeStyles,
  Theme
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { AuthContext, DialogContext, useLocalize } from '../../contexts';
import { useQueryClient } from 'react-query';
import Sync from 'components/Sync/Sync';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%'
  },
  logoWrapper: {
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tabLikeButton: {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: 16,
    marginRight: 10,

    '&:disabled': {
      color: '#fff'
    },

    '&::after': {
      backgroundColor: theme.palette.primary.main,
      bottom: -1,
      content: '""',
      display: 'block',
      height: 1,
      left: 0,
      position: 'absolute',
      width: '100%'
    }
  },
  button: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: 16
  }
}));

const Navigation = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>();
  const { user, setUser } = useContext(AuthContext);
  const { localize } = useLocalize();
  const queryClient = useQueryClient();
  const { openDialog } = useContext(DialogContext);

  const handleUserDropDownClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropDown = () => {
    setAnchorEl(null);
  };

  const handleSignOutClick = () => {
    if (user?.capabilities?.API_SYNC === 1) {
      openDialog(<Sync />);
      return;
    }

    setUser(null);
    closeDropDown();
    queryClient.clear();
  };

  return (
    <Box display="flex" className={classes.wrapper}>
      <Box className={classes.logoWrapper}>
        <img src="/logo.png" alt="logo" style={{ height: 35, width: 215.5 }} />
      </Box>

      <Box display="flex" marginRight={2} width="100%">
        <Button className={classes.tabLikeButton} disabled>
          {localize(306)}
        </Button>
        <Button
          className={classes.button}
          onClick={handleUserDropDownClick}
          startIcon={<AccountCircle />}
          style={{ marginLeft: 'auto' }}
        >
          {user?.username}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeDropDown}
          elevation={0}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          PaperProps={{
            style: {
              marginTop: 30,
              width: 160
            }
          }}
        >
          <MenuItem onClick={closeDropDown}>{localize(114)}</MenuItem>
          <MenuItem onClick={handleSignOutClick}>{localize(188)}</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default Navigation;
