import React, { createContext, useState } from 'react';
import { User } from '../interfaces';

interface AuthContextProps {
  user: User | null | undefined;
  setUser: (user: User | null) => void;
}

const initialValue = {
  user: null,
  setUser: () => alert('err')
};

export const AuthContext = createContext<AuthContextProps>(initialValue);

export default function AuthContextProvider({ children }: { children: any }) {
  const [user, setUser] = useState<User | null>(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}
