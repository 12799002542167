import '@fullcalendar/react/dist/vdom';
import FullCalendar, {
  EventClickArg,
  EventContentArg,
  SlotLabelContentArg
} from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import daLocale from '@fullcalendar/core/locales/da';
import { FULLCALENDAR_LICENSE_KEY, QUERY_KEYS } from 'constants/constants';
import dayjs from 'dayjs';
import dayjsDa from 'dayjs/locale/da';
import { Box, makeStyles } from '@material-ui/core';
import { useGetTimeline } from 'api/useInsertion';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { DataContext, DialogContext } from 'contexts';
import { Insertion, Timeslot } from 'interfaces';
import InsertionCreate from './InsertionCreate';
import InsertionEdit from './InsertionEdit';
import { SnackbarContext } from 'components/Snackbar/Snackbar';
import { insertionsToEvents, timeslotsToResources } from './scheduleHelper';
import './Scheduler.css';

const useStyles = makeStyles({
  calendar: {
    backgroundColor: '#fff',
    color: '#1a1a1a'
  }
});

interface SchedulerProps {
  showDroppableText: boolean;
}

const Scheduler: React.FC<SchedulerProps> = ({ showDroppableText }) => {
  dayjs.locale({ ...dayjsDa });
  const classes = useStyles();
  const { openDialog, setCallbackOnClose } = useContext(DialogContext);
  const { createSnack } = useContext(SnackbarContext);
  const { currentChannel, setSchedulerResourcesCount } =
    useContext(DataContext);
  const { getTimeline } = useGetTimeline();
  const { data, isError } = useQuery<{
    insertions: Insertion[];
    timeslots: Timeslot[];
  }>(
    [QUERY_KEYS.INSERTIONS, currentChannel?.id],
    () => getTimeline(currentChannel),
    {
      enabled: currentChannel !== undefined
    }
  );

  const resources = timeslotsToResources(data?.timeslots);
  const events = insertionsToEvents(data?.insertions);

  useEffect(() => {
    if (resources) {
      setSchedulerResourcesCount(resources.length);
    }
  }, [resources, setSchedulerResourcesCount]);

  if (isError) {
    createSnack(`getTimeline request failed!`, { severity: 'error' });
    return null;
  }

  const handleEventClick = (arg: EventClickArg) => {
    const insertion = data?.insertions.find((i) => i.id === +arg.event.id);
    if (insertion) {
      openDialog(
        <InsertionEdit
          insertion={insertion}
          timeslots={data?.timeslots || []}
        />
      );
    }
  };

  const handleEventReceive = (arg: EventReceiveArg) => {
    setCallbackOnClose(arg.revert);
    openDialog(
      <InsertionCreate droppedEvent={arg} timeslots={data?.timeslots || []} />
    );
  };

  const eventContent = (arg: EventContentArg) => arg.event.title;
  const resourceAreaHeaderContent = (arg: any) => currentChannel?.name;
  const slotLabelContent = (arg: SlotLabelContentArg) => {
    const weekdayNames = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];
    const tooltip = dayjs(arg?.date).format('dddd D. MMMM YYYY');
    const dayOfMonth = dayjs(arg?.date).date(); //1-31
    const dayOfWeek = dayjs(arg?.date).day(); //0-6
    const weekdayName = weekdayNames[dayOfWeek];

    return (
      <span title={tooltip}>
        {weekdayName}
        <br />
        {dayOfMonth}
      </span>
    );
  };

  return (
    <Box
      className={`${classes.calendar} ${
        showDroppableText ? 'fc-show-droppable-text' : ''
      }`}
    >
      <FullCalendar
        schedulerLicenseKey={FULLCALENDAR_LICENSE_KEY}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        droppable={true}
        editable={false}
        eventClick={handleEventClick}
        eventContent={eventContent}
        eventReceive={handleEventReceive}
        events={events}
        height="auto"
        initialView="resourceTimelineMonth"
        locale={daLocale}
        resourceAreaHeaderContent={resourceAreaHeaderContent}
        resourceAreaWidth="125px"
        resources={resources}
        selectable={false}
        selectMirror={true}
        slotLabelContent={slotLabelContent}
      />
    </Box>
  );
};

export default Scheduler;
