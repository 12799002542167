import React from 'react';
import PlaylistForm from './PlaylistForm/PlaylistForm';
import { PlaylistFormValues } from '../../interfaces';

function PlaylistCreate() {
  const formValues: PlaylistFormValues = {
    id: null,
    color: '',
    name: '',
    comment: '',
    content: [],
    medias: []
  };

  return <PlaylistForm formValues={formValues} />;
}

export default PlaylistCreate;
