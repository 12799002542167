import React, { createContext, useState } from 'react';
import { Channel, LastUpdatedMedia } from '../interfaces';

interface DataContextProps {
  currentChannel?: Channel;
  lastUpdatedMedia?: LastUpdatedMedia;
  schedulerResourcesCount: number;
  setCurrentChannel: (channel: Channel) => void;
  setLastUpdatedMedia: (item: LastUpdatedMedia) => void;
  setSchedulerResourcesCount: (value: number) => void;
}

const initialValue = {
  schedulerResourcesCount: 2,
  setCurrentChannel: (channel: Channel) => alert('err'),
  setLastUpdatedMedia: (item: LastUpdatedMedia) => alert('err'),
  setSchedulerResourcesCount: (value: number) => alert('err')
};

export const DataContext = createContext<DataContextProps>(initialValue);

export default function DataContextProvider({ children }: { children: any }) {
  const [currentChannel, setCurrentChannel] = useState<Channel>();
  const [schedulerResourcesCount, setSchedulerResourcesCount] =
    useState<number>(2);
  const [lastUpdatedMedia, __setLastUpdatedMedia] =
    useState<LastUpdatedMedia>();

  const setLastUpdatedMedia = (item: LastUpdatedMedia) => {
    __setLastUpdatedMedia({
      id: item.id,
      name: item.name,
      cacheBurst: Date.now()
    });
  };

  return (
    <DataContext.Provider
      value={{
        currentChannel,
        schedulerResourcesCount,
        setCurrentChannel,
        lastUpdatedMedia,
        setLastUpdatedMedia,
        setSchedulerResourcesCount
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
