import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataContext } from '../../contexts';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import { Playlist } from '../../interfaces';

const useStyles = makeStyles({
  card: {
    width: '100%'
  }
});

interface PlaylistListItemProps {
  playlist: Playlist;
  Draggable: any;
}

const PlaylistListItem: React.FC<PlaylistListItemProps> = ({
  playlist,
  Draggable
}) => {
  const classes = useStyles();
  const { setLastUpdatedMedia } = useContext(DataContext);
  let elRef = useRef<HTMLDivElement>(null);
  let history = useHistory();

  const handleEditClick = () => {
    setLastUpdatedMedia({});
    history.push(`/playlists/edit/${playlist.id}`);
  };

  const handleDeleteClick = () => {
    if (window.confirm('woot?')) {
    }
  };

  useEffect(() => {
    if (!elRef.current) {
      return;
    }

    let draggable = new Draggable(elRef.current, {
      eventData: () => ({
        id: playlist.id,
        title: playlist.name,
        backgroundColor: '#' + playlist.color,
        borderColor: '#' + playlist.color,
        create: true
      })
    });

    return () => draggable.destroy();
  });

  return (
    <Card className={classes.card} ref={elRef}>
      <Box display="flex" justifyContent="space-between" position="relative">
        <Box display="flex" alignItems="center" style={{ paddingLeft: 24 }}>
          <span
            style={{
              backgroundColor: `#${playlist.color}`,
              display: 'inline-block',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: 16
            }}
          ></span>
          <Typography
            style={{
              color: `rgb(0,0,0)`
            }}
            variant="body2"
          >
            {playlist.name}
          </Typography>
          <span
            style={{
              color: `rgba(0,0,0,.5)`,
              display: 'inline-block',
              marginLeft: 5
            }}
          >
            {playlist.id}
          </span>
        </Box>
        <Box className="button-group" display="flex" alignItems="center">
          <IconButton
            color="primary"
            size="small"
            title="Rediger playlisten"
            onClick={handleEditClick}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            title="Træk playlisten ned på tidslinjen for at skemalægge den."
            style={{ cursor: 'move' }}
          >
            <DragIndicatorIcon />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
};

export default PlaylistListItem;
