import { SnackbarContext } from 'components/Snackbar/Snackbar';
import { useLocalize } from 'contexts';
import { useContext } from 'react';
import { useFetcher } from './useFetcher';

export const useSync = () => {
  const { fetcher } = useFetcher();
  const { createSnack } = useContext(SnackbarContext);
  const { localize } = useLocalize();

  const sync = async (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        await fetcher(
          'perform_sync.php?export_all=true&group_sync=true&logout=true'
        );
        createSnack(localize(291), {});
        resolve();
      } catch (error) {
        createSnack(`Synchronization failed! [${error}]`, {
          severity: 'error'
        });
        reject();
      }
    });
  };

  return { sync };
};
