import React, { createContext, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Portal } from '@material-ui/core';

interface SnackbarContextProps {
  createSnack: (message: string, options?: any) => void;
}

export const SnackbarContext = createContext<SnackbarContextProps>({
  createSnack: (message, options) => {}
});

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    alignItems: 'center',
    width: 400
  },
  alertMessage: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto',
    maxWidth: 'calc(100% - 70px)'
  },
  alertSuccess: {
    backgroundColor: '#189a5b',
    color: '#fff',

    '& .MuiAlert-icon': { color: '#fff' }
  },
  alertError: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',

    '& .MuiAlert-icon': { color: '#fff' }
  }
}));

interface RenderProps {
  id: number;
  message: string;
  open: boolean;
  options: {
    severity?: 'success' | 'error';
  };
  handleClose?: () => void;
}

export const RenderSnack: React.FC<RenderProps> = ({
  id,
  message,
  open,
  options: { severity = 'success' },
  handleClose
}) => {
  const classes = useStyles();
  const messageId = `message-${id}`;

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={severity === 'error' ? null : 4000}
        onClose={(event, reason) => {
          if (reason !== 'clickaway' && handleClose) {
            handleClose();
          }
        }}
        ContentProps={{
          'aria-describedby': messageId
        }}
        style={{ zIndex: 999999 }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="standard"
          classes={{
            root: classes.alertRoot,
            message: classes.alertMessage,
            standardError: classes.alertError,
            standardSuccess: classes.alertSuccess
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

let uniqueId = 2;

interface QueueState {
  current: Snack | null;
  queue: Snack[];
}
interface Snack {
  id: number;
  message: string;
  open: boolean;
  options: any;
}

export const SnackProvider: React.FC = ({ children }) => {
  const [{ current, queue }, setState] = useState<QueueState>({
    current: null,
    queue: []
  });

  const createSnack = (message: string, options?: any) => {
    const id = uniqueId++;
    const snack = { id, message, open: true, options };

    if (current) {
      setState({ current, queue: queue.concat(snack) });
    } else {
      setState({ queue, current: snack });
    }

    return id;
  };

  const handleClose = () => {
    setState((currentState: QueueState) => ({
      current: currentState.current
        ? { ...currentState.current, open: false }
        : null,
      queue: [...currentState.queue]
    }));
    // time to snack close animation
    setTimeout(openNext, 1000);
  };

  const openNext = () => {
    if (queue.length) {
      setState({ current: queue[0], queue: queue.slice(1) });
    } else {
      setState({ current: null, queue: [] });
    }
  };

  return (
    <SnackbarContext.Provider value={{ createSnack }}>
      {current && (
        <RenderSnack {...current} handleClose={handleClose} key={current.id} />
      )}
      {children}
    </SnackbarContext.Provider>
  );
};
