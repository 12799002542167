import { useFetcher } from './useFetcher';
import { SnackbarContext } from '../components/Snackbar/Snackbar';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { objToFormData } from './formData';
import { QUERY_KEYS } from '../constants/constants';
import { AuthContext, DataContext } from 'contexts';
import { xml2json } from 'lib/xml-parser';
import { Channel, Insertion, Timeslot } from 'interfaces';
import dayjs from 'dayjs';
import { useGetPlaylist } from './usePlaylist';
import { htmlDecode } from 'helpers/htmlHelper';
import { objectToArray } from 'helpers/arrayHelper';

export const useGetTimeline = () => {
  const { user } = useContext(AuthContext);
  const { fetcher } = useFetcher();

  const getTimeline = async (
    channel?: Channel
  ): Promise<{
    insertions: Insertion[];
    timeslots: Timeslot[];
  }> => {
    const result = await fetcher(
      `get_timeline.php?group_id=${channel?.id}&location_id=&uid=${user?.username}`
    );

    // <timeline>
    // <insertion id="1274" start_date="1610064000000" timeslot_id="1011" playlist_id="1270" status="0" end_date="1610668800000" playlist_color="F12C36">demo test1</insertion>
    // <insertion id="1275" start_date="1610064000000" timeslot_id="1202" playlist_id="1270" status="0" end_date="1610409600000" playlist_color="F12C36">demo test1</insertion>
    // <timeslots>
    // <timeslot id="1011" start="00:00:00"><![CDATA[Nat]]></timeslot>
    // <timeslot id="1202" start="06:00:00"><![CDATA[Dag]]></timeslot>
    // </timeslots>
    // </timeline>

    return new Promise((resolve) => {
      const json = xml2json(result, 'title');
      let timeslots = json?.timeline?.timeslots?.timeslot || [];
      let insertion = json?.timeline?.insertion || [];
      let insertions = [];

      // The xml2json parser will return either an object or an array of objects.
      // Let's make it easy and put a single object into an array.
      timeslots = objectToArray(timeslots);

      // prettier-ignore
      if (insertion) {
        // Same as above, xml2json parser will return either an insertion object or an array of insertions.
        // Let's make it easy and put a single object into an array.
        if (!Array.isArray(insertion)) {
          insertion = [insertion];
        }

        insertions =
          insertion.map((insertion: Insertion) => ({
            ...insertion,
            title: htmlDecode(insertion.title)
          })) || [];
      }

      resolve({ insertions, timeslots });
    });
  };
  return { getTimeline };
};

export const useCreateInsertion = () => {
  const { fetcher } = useFetcher();
  const { currentChannel } = useContext(DataContext);
  const { createSnack } = useContext(SnackbarContext);
  const { getPlaylist } = useGetPlaylist();
  const queryClient = useQueryClient();

  const formatStartDate = (date: Date): number => {
    // Handle daylight saving time
    // https://github.com/iamkun/dayjs/issues/1271
    const tzOffset = Math.abs(date.getTimezoneOffset());

    return dayjs(date)
      .hour(0)
      .minute(tzOffset)
      .second(0)
      .millisecond(0)
      .valueOf();
  };

  const formatEndDate = (date: Date | null): number | string => {

    if (date){
    const tzOffset = Math.abs(date.getTimezoneOffset());
    return dayjs(date).hour(24).minute(tzOffset).second(0).millisecond(0).valueOf();
    } else {
      return '';
    }

    // return date ? dayjs(date).hour(24).minute(tzOffset).second(0).millisecond(0).valueOf()
    //  : '';
  };

  const createInsertion = async (
    playlistId: number,
    startDate: Date,
    endDate: Date | null,
    timeslots: Timeslot[],
    createSilently = false,
    playlistModified = false
  ): Promise<void> => {
    const playlist = await getPlaylist(playlistId);
    if (!playlist) {
      createSnack('Nah not so much!', { severity: 'error' });
      return;
    }

    const formData = objToFormData({
      insertion_start: formatStartDate(startDate),
      insertion_end: formatEndDate(endDate),
      insertion_timeslots: timeslots.map((t) => t.title),
      insertion_endpoints: '',
      group_id: currentChannel?.id,
      playlist_id: playlist?.id,
      playlist_color: playlist?.color,
      playlist_title: playlist?.name,
      playlist_content: playlist?.item?.map((i) => i.id) || [],
      playlist_modified: playlistModified,
      playlist_comment: playlist?.comment
    });

    const result = await fetcher('add_insertion.php', {
      method: 'POST',
      body: formData
    });

    return new Promise((resolve, reject) => {
      if (result.startsWith('result=true')) {
        if (!createSilently) {
          queryClient.invalidateQueries(QUERY_KEYS.INSERTIONS);
          createSnack('Indrykningen blev oprettet.', {});
        }
        resolve();
      } else {
        createSnack('Nah not so much!', { severity: 'error' });
        reject();
      }
    });
  };
  return { createInsertion };
};

// The Spotmanager API doesn't support editing an
// insertion, so we have to delete the existing insertion
// and create a new one using the edited data.
export const useEditInsertion = () => {
  const { deleteInsertion } = useDeleteInsertion();
  const { createInsertion } = useCreateInsertion();
  const { createSnack } = useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const editInsertion = async (
    playlistId: number,
    insertionId: number,
    startDate: Date,
    endDate: Date | null,
    timeslot: Timeslot
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const deletePromise = deleteInsertion(insertionId, true);
      const createPromise = createInsertion(
        playlistId,
        startDate,
        endDate,
        [timeslot],
        true,
        true
      );

      Promise.all([deletePromise, createPromise])
        .then(() => {
          queryClient.refetchQueries(QUERY_KEYS.INSERTIONS, { stale: false });
          createSnack('Indrykningen blev ændret.', {});
          resolve();
        })
        .catch(() => {
          createSnack('Nah not so much!', { severity: 'error' });
          reject();
        });
    });
  };
  return { editInsertion };
};

export const useDeleteInsertion = () => {
  const { fetcher } = useFetcher();
  const { createSnack } = useContext(SnackbarContext);
  const { currentChannel } = useContext(DataContext);
  const queryClient = useQueryClient();

  const deleteInsertion = async (
    insertionId: number,
    deleteSilently = false
  ): Promise<void> => {
    const formData = objToFormData({
      insertions: insertionId,
      group_id: currentChannel?.id,
      fillgap: 'false'
    });
    const result = await fetcher('remove_insertions.php', {
      method: 'POST',
      body: formData
    });

    return new Promise((resolve, reject) => {
      if (result.startsWith('result=true')) {
        if (!deleteSilently) {
          queryClient.invalidateQueries(QUERY_KEYS.INSERTIONS);
          createSnack('Indrykningen blev fjernet.', {});
        }
        resolve();
      } else {
        createSnack('Nah not so much!', { severity: 'error' });
        reject();
      }
    });
  };
  return { deleteInsertion };
};
