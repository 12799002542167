import dayjs from 'dayjs';
import { Insertion, Timeslot } from 'interfaces';

export const timeslotsToResources = (timeslots: Timeslot[] | undefined) => {
  return (
    timeslots?.map((timeslot: Timeslot) => ({
      id: timeslot.id.toString(),
      title: timeslot.title
    })) || []
  );
};

export const insertionsToEvents = (insertions: Insertion[] | undefined) => {
  const events =
    insertions?.map((insert: Insertion) => {
      // const isGrouped =
      //   insertions.filter(
      //     (x: Insertion) =>
      //       x.playlist_id === insert.playlist_id &&
      //       x.start_date === insert.start_date &&
      //       x.end_date === insert.end_date &&
      //       x.timeslot_id !== insert.timeslot_id
      //   ).length > 0;

      // const groupId = isGrouped
      //   ? insert.playlist_id.toString() +
      //     insert.start_date.toString() +
      //     insert.end_date.toString()
      //   : '';

      return {
        id: insert.id.toString(),
        groupId: '', // groupId,
        resourceId: insert.timeslot_id.toString(),
        title: insert.title,
        start: dayjs(insert.start_date).startOf('day').valueOf(),
        end: insert.end_date
          ? dayjs(insert.end_date).startOf('day').valueOf()
          : dayjs().add(10, 'years').valueOf(),
        backgroundColor: `#${insert.playlist_color}`,
        borderColor: `#${insert.playlist_color}`
      };
    }) || [];
  return events;
  // return events.filter((event) => event.end > event.start);
};
