import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { objToFormData } from './formData';
import md5 from 'crypto-js/md5';
import { API_URL } from '../constants/constants';

export const useSignIn = () => {
  const { setUser } = useContext(AuthContext);

  const signIn = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    //check if password is already hashed, otherwise hash it.
    const hashedPassword = password.match(/^[a-f0-9]{32}$/gi) ? password : md5(password).toString();
    const formData = objToFormData({
      username,
      password: hashedPassword
    });

    const response = await fetch(API_URL + 'login.php', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    const text = await response.text();

    return new Promise((resolve, reject) => {
      if (response.status === 200) {
        try {
          const user = parseUserString(text);
          if (user.result) {
            user.password = hashedPassword;
            setUser(user);
            resolve(true);
          } else {
            // error
            console.error('Incorrect login');
            reject();
          }
        } catch (error) {
          console.error('catch error', error);
          reject();
        }
      }
    });
  };

  return { signIn };
};

const parseUserString = (input: string): any => {
  // input will look like: result=true&is_admin=1&is_superuser=false&us
  const chunks = input.split('&');
  return chunks.reduce((obj: any, chunk: string) => {
    const keyvalue = chunk.split('=');
    const key = keyvalue[0];
    const value = keyvalue[1];

    switch (key) {
      case 'result': {
        obj[key] = value === 'true';
        break;
      }
      case 'is_admin': {
        obj[key] = value === '1';
        break;
      }
      case 'is_superuser': {
        obj[key] = value === 'true';
        break;
      }
      case 'user_id': {
        obj[key] = parseInt(value);
        break;
      }
      case 'capabilities': {
        obj[key] = parseUserCapabilitiesString(chunk);
        break;
      }
      default: {
        obj[key] = value;
      }
    }
    return obj;
  }, {});
};

const parseUserCapabilitiesString = (input: string): any => {
  // input will look like: capabilities=API_SYNC=1,BLACKLISTING
  const capabilities = input.substring(13).split(',');
  return capabilities.reduce((obj: any, capability: string) => {
    const keyvalue = capability.split('=');
    const key = keyvalue[0];
    const value = keyvalue[1] || '0';
    obj[key] = parseInt(value);
    return obj;
  }, {});
};
