import React from 'react';
import {
  Button,
  Popover,
  makeStyles,
  IconButton,
  Typography,
  Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useField } from 'formik';
import { HexColorPicker } from 'react-colorful';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex'
  },
  formLabel: {
    marginRight: 10
  },
  button: {
    borderRadius: 3,
    height: 20,
    minWidth: 0,
    padding: 0,
    width: 200
  },
  roundButton: {
    borderRadius: 12,
    height: 24,
    minWidth: 0,
    padding: 0,
    width: 24
  },
  roundButtonActive: {
    border: '2px solid #fff'
  },
  paper: {
    overflow: 'visible',
    padding: '5px 10px 10px 10px',
    width: 220
  },
  colorWrapper: {
    display: 'flex',
    flexFlow: 'wrap',
    marginTop: 20,
    justifyContent: 'flex-start'
  },
  color: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    width: '20%'
  }
}));

const ColorPicker = () => {
  const [field, , helpers] = useField({ name: 'color' });
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpenColorPickerClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColorPickerClick = () => {
    handlePopupClose();
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };

  const handleSelectColorClick = (color: string) => {
    helpers.setValue(color?.replace('#', ''));
    helpers.setTouched(true, false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-popover' : undefined;
  const palette = [
    '#808080',
    '#f12c36',
    '#f57a34',
    '#fff500',
    '#9fd154',
    '#00b567',
    '#00bcf2',
    '#0076b8',
    '#844d71',
    '#ff96c5',
    '#ff69b4'
  ];

  return (
    <>
      <Button
        className={classes.button}
        style={{ backgroundColor: `#${field.value}` }}
        onClick={handleOpenColorPickerClick}
        title="Vælg farve"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          paper: classes.paper
        }}
        BackdropProps={{ invisible: false }}
      >
        <Box marginBottom={1} marginTop={0.5}>
          <Typography variant="body1">Vælg farve</Typography>
        </Box>

        <IconButton
          size="small"
          onClick={handleCloseColorPickerClick}
          style={{
            position: 'absolute',
            right: 5,
            top: 5,
            zIndex: 1
          }}
        >
          <CloseIcon />
        </IconButton>

        <HexColorPicker
          color={`#${field.value}`}
          onChange={handleSelectColorClick}
        />

        <div className={classes.colorWrapper}>
          {palette.map((color) => (
            <div className={classes.color} key={color}>
              <Button
                className={`${classes.roundButton} ${
                  color.endsWith(field.value) ? classes.roundButtonActive : ''
                }`}
                style={{ backgroundColor: color }}
                onClick={() => handleSelectColorClick(color)}
              />
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default ColorPicker;
