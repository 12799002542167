import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { InputBase } from 'formik-material-ui';
import { useSavePlaylist, useDeletePlaylist } from '../../../api/usePlaylist';
import { PlaylistFormValues } from '../../../interfaces';
import * as Yup from 'yup';
import ColorPicker from './ColorPicker';
import MediaList from '../../Media/MediaList';
import SelectedMedias from './SelectedMedias';

const useStyles = makeStyles((theme: Theme) => ({
  form: { display: 'flex', width: '100%' },
  formTop: {
    borderBottom: '1px solid #000',
    margin: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  formBody: {
    flex: 1,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },

  inputWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    marginRight: theme.spacing(2),
    padding: '1px 9px',
    width: 250
  },
  inputNameWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    marginRight: theme.spacing(2),
    padding: '1px 9px',
    width: 400
  },
  inputWrapperError: {
    borderColor: theme.palette.secondary.main
  },
  inputLabel: {
    opacity: 0.75,
    marginRight: theme.spacing(1)
  },
  inputField: {
    flex: 1
  }
}));

interface PlaylistFormProps {
  formValues: PlaylistFormValues;
}

const PlaylistForm: React.FC<PlaylistFormProps> = ({ formValues }) => {
  const classes = useStyles();
  let history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);
  const { savePlaylist } = useSavePlaylist();
  const { deletePlaylist } = useDeletePlaylist();
  const validationSchema = getValidationSchema();

  const handleCopyClick = () => {
    history.push(`/playlists/copy/${formValues.id}`);
  };

  const handleDeleteClick = async () => {
    if (window.confirm('Ønsker du at slette playlisten?')) {
      setIsDeleting(true);
      await deletePlaylist(formValues.id!);
      history.push('/');
    }
  };

  return (
    <Formik
      initialValues={{ ...formValues }}
      validationSchema={validationSchema}
      onSubmit={savePlaylist}
    >
      {({ errors, touched, values }) => (
        <Form className={classes.form}>
          <Grid container>
            <Grid sm={4} item style={{ borderRight: '1px solid #312e6b' }}>
              <MediaList />
            </Grid>
            <Grid sm={8} container item direction="column">
              <Box className={classes.formTop}>
                <Box display="flex">
                  <Box marginRight={5}>
                    <Typography variant="h6">
                      {formValues.id ? 'Rediger playlist' : 'Ny playlist'}
                    </Typography>
                  </Box>

                  <Paper
                    className={`${classes.inputWrapper} ${
                      errors.color && touched.color
                        ? classes.inputWrapperError
                        : ''
                    }`}
                  >
                    <label className={classes.inputLabel}>Farve:</label>
                    <ColorPicker />
                  </Paper>

                  <Paper
                    className={`${classes.inputNameWrapper} ${
                      errors.name && touched.name
                        ? classes.inputWrapperError
                        : ''
                    }`}
                  >
                    <label className={classes.inputLabel}>Navn:</label>
                    <Field name="name" component={InputBase} fullWidth />
                  </Paper>
                </Box>
              </Box>

              <Box className={classes.formBody}>
                <SelectedMedias />
              </Box>

              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Box margin={3}>
                    {formValues?.id && (
                      <ButtonGroup>
                        <Button
                          variant="outlined"
                          disabled={isDeleting}
                          onClick={handleCopyClick}
                        >
                          Kopier
                        </Button>
                        <Button
                          color="secondary"
                          variant="outlined"
                          disabled={isDeleting}
                          onClick={handleDeleteClick}
                        >
                          Slet
                        </Button>
                      </ButtonGroup>
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  <Box margin={3}>
                    <ButtonGroup>
                      <Button
                        variant="outlined"
                        disabled={isDeleting}
                        onClick={() => history.push('/')}
                      >
                        Annuller
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isDeleting}
                        type="submit"
                      >
                        {formValues?.id ? 'Godkend' : 'Opret'}
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const getValidationSchema = () =>
  Yup.object().shape({
    color: Yup.string().required(),
    name: Yup.string().required(),
    medias: Yup.array().min(1).required()
  });

export default PlaylistForm;
