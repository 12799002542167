import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { MediaItem } from '../../interfaces';
import MediaCard from '../Media/MediaCard';
import { useDebounce, usePrevious } from '../../hooks';
import MediaUploadDialog from '../Media/MediaUpload';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { DataContext, DialogContext } from 'contexts';
import { useGetMediaItems } from 'api/useMedia';
import { SnackbarContext } from 'components/Snackbar/Snackbar';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from 'constants/constants';

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    display: 'flex',
    height: 'calc(100vh - 278px)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  mediaListWrapper: {
    marginBottom: 27,
    marginRight: 8,
    marginLeft: theme.spacing(3),
    overflowY: 'scroll'
  },
  inputWrapper: {
    backgroundColor: theme.palette.primary.dark,
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    flex: 1
  }
}));

const MediaList: React.FC = () => {
  const classes = useStyles();
  let history = useHistory();
  const { createSnack } = useContext(SnackbarContext);
  const { getMediaItems } = useGetMediaItems();
  const { schedulerResourcesCount } = useContext(DataContext);
  const { openDialog } = useContext(DialogContext);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sortBy, setSortBy] = useState<'id' | 'name' | 'type'>('id');
  const [filterByActivated, setFilterByActivated] = useState<
    'all' | 'activated' | 'not_activated'
  >('activated');
  const debouncedSearchPhrase = useDebounce(searchPhrase, 300);
  const previousSearchCriteria = usePrevious({
    debouncedSearchPhrase: '',
    sortBy: 'id',
    filterByActivated: 'all'
  });
  const { data, isLoading, isError } = useQuery<MediaItem[]>(
    QUERY_KEYS.MEDIA_ITEMS,
    getMediaItems
  );

  const heightOffset = 487.5 + schedulerResourcesCount * 34;

  const handleUploadClick = () => {
    setFilterByActivated('all');
    openDialog(<MediaUploadDialog />);
  };

  const medias = data
    ?.filter((media) => {
      // Filter by activation state
      if (
        (filterByActivated === 'activated' && !media.id) ||
        (filterByActivated === 'not_activated' && media.id)
      ) {
        return false;
      }

      // User's search phrase
      if (debouncedSearchPhrase) {
        // search by id
        if (media?.id?.toString() === debouncedSearchPhrase) {
          return true;
        }

        // search by name
        const regex = new RegExp(debouncedSearchPhrase, 'gi');
        return media?.name?.match(regex) || false;
      }

      return true;
    })
    .sort((a, b) => {
      if (sortBy === 'name') {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }

      if (sortBy === 'type') {
        return a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1;
      }

      if (sortBy === 'id' && a.id && b.id) {
        return a.id > b.id ? -1 : 1;
      }

      return -1;
    });

  // Do lazy load check when search criteria changes
  useEffect(() => {
    if (
      previousSearchCriteria?.debouncedSearchPhrase !== debouncedSearchPhrase ||
      previousSearchCriteria?.sortBy !== sortBy ||
      previousSearchCriteria?.filterByActivated !== filterByActivated
    ) {
      forceCheck();
    }
  }, [
    previousSearchCriteria,
    debouncedSearchPhrase,
    sortBy,
    filterByActivated
  ]);

  if (isError) {
    createSnack(`getMediaItems request failed!`, { severity: 'error' });
    return null;
  }

  if (isLoading) {
    return (
      <Box className={classes.loading}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <>
      <Box margin={3}>
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Typography variant="h6">Media ({medias?.length || 0})</Typography>
          <Button size="small" variant="text" onClick={() => history.push('/')}>
            Gå tilbage
          </Button>
        </Box>

        <Box marginBottom={2}>
          <SearchField
            searchPhrase={searchPhrase}
            setSearchPhrase={setSearchPhrase}
          />
        </Box>

        <Box display="flex" marginBottom={2}>
          <Box flex="1" marginRight={1} width="50%">
            <SortByField sortBy={sortBy} setSortBy={setSortBy} />
          </Box>
          <Box flex="1" width="50%">
            <FilterByActivatedField
              filterByActivated={filterByActivated}
              setFilterByActivated={setFilterByActivated}
            />
          </Box>
        </Box>

        <Box marginBottom={5}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleUploadClick}
          >
            Upload nyt media
          </Button>
        </Box>
      </Box>

      <Box
        className={classes.mediaListWrapper}
        height={`calc(100vh - ${heightOffset}px)`}
        id="media-wrapper"
      >
        <Box display="flex" flexWrap="wrap">
          {medias?.map((media) => (
            <LazyLoad
              scrollContainer="#media-wrapper"
              throttle={100}
              height={200}
              once
              overflow
              offset={200}
              scroll
              style={{
                marginRight: 8,
                marginBottom: 8,
                width: 'calc(50% - 8px)'
              }}
              key={media.index}
            >
              <MediaCard
                item={{ ...media }}
                addButton
                style={{
                  height: '100%'
                }}
              />
            </LazyLoad>
          ))}
        </Box>
      </Box>
    </>
  );
};

interface SearchFieldProps {
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
  searchPhrase,
  setSearchPhrase
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  };

  return (
    <Paper className={classes.inputWrapper}>
      <InputBase
        className={classes.input}
        placeholder="Søg"
        onChange={handleChange}
        defaultValue={searchPhrase}
        type="search"
      />
      <SearchIcon />
    </Paper>
  );
};

interface SortByFieldProps {
  sortBy: string;
  setSortBy: (value: 'id' | 'name' | 'type') => void;
}

const SortByField: React.FC<SortByFieldProps> = ({ sortBy, setSortBy }) => {
  const classes = useStyles();
  const options = [
    { value: 'id', label: 'Vis nyeste først' },
    { value: 'name', label: 'Vis i alfabetisk rækkefølge' },
    { value: 'type', label: 'Vis efter medietype' }
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortBy(event.target.value as 'id' | 'name' | 'type');
  };

  return (
    <Paper className={classes.inputWrapper}>
      <TextField
        select
        value={sortBy}
        onChange={handleChange}
        fullWidth
        InputProps={{ disableUnderline: true }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Paper>
  );
};

interface FilterByActivatedFieldProps {
  filterByActivated: string;
  setFilterByActivated: (value: 'activated' | 'all' | 'not_activated') => void;
}

const FilterByActivatedField: React.FC<FilterByActivatedFieldProps> = ({
  filterByActivated,
  setFilterByActivated
}) => {
  const classes = useStyles();
  const options = [
    { value: 'activated', label: 'Vis kun aktiverede' },
    { value: 'all', label: 'Vis alle' },
    { value: 'not_activated', label: 'Vis ikke aktiverede' }
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByActivated(
      event.target.value as 'activated' | 'all' | 'not_activated'
    );
  };

  return (
    <Paper className={classes.inputWrapper}>
      <TextField
        select
        value={filterByActivated}
        onChange={handleChange}
        fullWidth
        InputProps={{ disableUnderline: true }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Paper>
  );
};

export default MediaList;
