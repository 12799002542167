import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      position="absolute"
      top={0}
      left={0}
      style={{ backgroundColor: 'rgba(0,0,0,.8)' }}
    >
      <Box marginBottom={1}>
        <CircularProgress size={50} />
      </Box>
      <Typography variant="body2">Indlæser...</Typography>
    </Box>
  );
};

export default Loading;
