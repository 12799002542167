import React, { useContext, useState, useEffect } from 'react';
import {
  Tab,
  Tabs,
  makeStyles,
  Theme,
  InputBase,
  Box
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { DataContext } from '../../contexts/DataContext';
import { useDebounce } from '../../hooks';
import { useGetChannels } from 'api/useChannel';
import { QUERY_KEYS } from 'constants/constants';
import { Channel } from 'interfaces';
import { useQuery } from 'react-query';
import { SnackbarContext } from 'components/Snackbar/Snackbar';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex'
  },
  scroller: {
    order: 1,
    paddingLeft: theme.spacing(1)
  },
  scrollButtons: {
    backgroundColor: theme.palette.primary.light,
    order: 0,
    width: 50
  },
  inputWrapper: {
    backgroundColor: theme.palette.primary.dark,
    alignItems: 'center',
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  input: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    minWidth: 150,
    flex: 1
  }
}));

const ChannelsList: React.FC = () => {
  const classes = useStyles();
  const { createSnack } = useContext(SnackbarContext);
  const { getChannels } = useGetChannels();
  const { currentChannel, setCurrentChannel } = useContext(DataContext);
  let history = useHistory();
  const selectedTab = currentChannel?.id || false;
  const [searchPhrase, setSearchPhrase] = useState('');
  const debouncedSearchPhrase = useDebounce(searchPhrase, 300);

  const { data: channels, isError } = useQuery<Channel[]>(
    QUERY_KEYS.CHANNELS,
    getChannels
  );

  // If no channel is selected, then select the first one.
  useEffect(() => {
    if (!currentChannel && channels && channels.length > 0) {
      setCurrentChannel(channels[0]);
    }
  }, [channels, currentChannel, setCurrentChannel]);

  if (isError) {
    createSnack(`getChannels request failed!`, { severity: 'error' });
    return null;
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, channelId: number) => {
    const channel = channels?.find((c) => c.id === channelId);
    if (channel) {
      setCurrentChannel(channel);
      history.push('/');
    }
  };

  const filtered = channels?.filter((item) => {
    // User's search phrase
    if (debouncedSearchPhrase) {
      const regex = new RegExp(debouncedSearchPhrase, 'gi');
      return item.name.match(regex);
    }

    return true;
  });

  return (
    <Box className={classes.wrapper}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="on"
        classes={{
          scroller: classes.scroller,
          scrollButtons: classes.scrollButtons
        }}
        TabScrollButtonProps={{ disabled: false }}
      >
        {filtered?.map((channel) => (
          <Tab label={channel.name} value={channel.id} key={channel.id} />
        ))}
      </Tabs>
      <SearchField
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
      />
    </Box>
  );
};

export default ChannelsList;

interface SearchFieldProps {
  searchPhrase: string;
  setSearchPhrase: (value: string) => void;
}

const SearchField: React.FC<SearchFieldProps> = ({
  searchPhrase,
  setSearchPhrase
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.target.value);
  };

  return (
    <Box className={classes.inputWrapper}>
      <InputBase
        className={classes.input}
        placeholder="Søg i kanaler"
        onChange={handleChange}
        defaultValue={searchPhrase}
        type="search"
      />
      <SearchIcon />
    </Box>
  );
};
