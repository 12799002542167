import React, { createContext, useContext, useState } from 'react';

export const LocalizationContext = createContext({
  languages: [],
  phrases: [],
  currentLanguageId: 1
});

export default function LocalizationContextProvider({
  data,
  children
}: {
  data: any;
  children: any;
}) {
  const [currentLanguageId, setCurrentLanguageId] = useState(1);
  const value = {
    languages: data.languages,
    phrases: data.phrases,
    currentLanguageId,
    setCurrentLanguageId
  };

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
}

export const useLocalize = (): { localize: (id: number) => string } => {
  const { phrases, currentLanguageId } = useContext<any>(LocalizationContext);
  const localize = (id: number): string => {
    return phrases
      ?.find((x: any) => x.id === id)!
      .text.find((x: any) => x.lang_id === currentLanguageId)!.text;
  };
  return { localize };
};
