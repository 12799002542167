import React, { useContext, useState } from 'react';
import { DialogContext } from 'contexts';
import {
  Box,
  Button,
  ButtonGroup,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
  FormGroup,
  makeStyles,
  Theme,
  Paper,
  IconButton
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EventReceiveArg } from '@fullcalendar/interaction';
import { Timeslot } from 'interfaces';
import { useCreateInsertion } from 'api/useInsertion';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) => ({
  inputWrapper: {
    backgroundColor: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: '5px 9px',
    position: 'relative'
  },
  inputIcon: {
    cursor: 'pointer',
    right: 8,
    opacity: 0.5,
    position: 'absolute',
    top: 22
  }
}));

interface InsertionCreateProps {
  droppedEvent: EventReceiveArg;
  timeslots: Timeslot[];
}

// prettier-ignore
const InsertionCreate: React.FC<InsertionCreateProps> = ({
  droppedEvent,
  timeslots
}) => {
  const classes = useStyles();
  const { createInsertion } = useCreateInsertion();
  const { closeDialog } = useContext(DialogContext);
  const [ startDate, handleStartDateChange ] = useState<Date>(droppedEvent.event.start!);
  const [ endDate, handleEndDateChange ] = useState<Date | null>(null);
  const initiallySelectedTimeslot = droppedEvent.event._def.resourceIds?.map((id) => +id) || [];
  const [ selectedTimeslot, setSelectedTimeslots ] = React.useState<number[]>(initiallySelectedTimeslot);

  const handleTimeslotChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const value = +event.target.value;
    const selected = checked
      ? [...selectedTimeslot, value]
      : [...selectedTimeslot.filter((id) => id !== value)];

    setSelectedTimeslots(selected);
  };

  const handleCreateClick = async () => {
    const playlistId = +droppedEvent.event.id;
    const selectedTimeslots = timeslots.filter((x) => selectedTimeslot.includes(x.id));
    await createInsertion(playlistId, startDate, endDate, selectedTimeslots);
    closeDialog();
  };

  const handleCloseClick = () => {
    closeDialog();
  };

  return (
    <React.Fragment>
      <Box flex="1" margin={2} marginTop={2} marginBottom={0}>
        <Box marginBottom={3}>
          <Typography variant="h2" align="center">
            Bekræft skemalægning
            <br />
            &quot;{droppedEvent.event.title}&quot;
          </Typography>
        </Box>

        <Box marginBottom={4}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Paper className={classes.inputWrapper}>
                <DatePicker
                  label="Start dato"
                  fullWidth
                  maxDate={endDate}
                  disableToolbar
                  variant="inline"
                  autoOk
                  value={startDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    if (date) {
                      handleStartDateChange(date);
                    }
                  }}
                />
              </Paper>
            </Grid>
            <Grid item sm={6}>
              <Paper className={classes.inputWrapper}>
                <DatePicker
                  label="Slut dato"
                  fullWidth
                  minDate={startDate}
                  disableToolbar
                  variant="inline"
                  autoOk
                  value={endDate}
                  onChange={(date: MaterialUiPickersDate) => {
                    handleEndDateChange(date);
                  }}
                />
                <IconButton
                  className={classes.inputIcon}
                  size="small"
                  onClick={() => handleEndDateChange(null)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Box marginBottom={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Tidsintervaller</FormLabel>
            <FormGroup>
              {timeslots.map((timeslot) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedTimeslot.includes(timeslot.id)}
                      onChange={handleTimeslotChange}
                      value={timeslot.id}
                    />
                  }
                  label={timeslot.title}
                  key={timeslot.id}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>

      <Box margin={2}>
        <ButtonGroup fullWidth>
          <Button variant="outlined" fullWidth onClick={handleCloseClick}>
            Annuller
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleCreateClick}
          >
            Godkend
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  );
};

export default InsertionCreate;
