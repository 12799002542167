import React, { SyntheticEvent, useContext, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { DialogContext } from '../../contexts';
import { MediaItem } from '../../interfaces';
import { useGenerateThumbnail } from '../../api/useMedia';

const useStyles = makeStyles({
  image: {
    height: 263,
    margin: 'auto',
    maxWidth: '100%',
    width: 'auto'
  },
  video: {
    backgroundColor: 'rgba(0,0,0,.9)'
  }
});

interface MediaActivateProps {
  item: MediaItem;
  previewUrl: string;
}

const MediaActivate: React.FC<MediaActivateProps> = ({ item, previewUrl }) => {
  const classes = useStyles();
  const { closeDialog } = useContext(DialogContext);
  const { generateThumbnail } = useGenerateThumbnail();
  const [pauseVideoTime, setVideoPauseTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateThumbnailClick = async () => {
    setIsLoading(true);
    const result = await generateThumbnail(item, previewUrl, pauseVideoTime);
    if (result) {
      closeDialog();
    }
  };

  const handleVideoTimeUpdate = (
    event: SyntheticEvent<HTMLVideoElement, Event>
  ) => {
    const video = event.target as HTMLVideoElement;
    if (video) {
      setVideoPauseTime(video.currentTime);
    }
  };

  return (
    <React.Fragment>
      <Box flex="1" margin={2} marginTop={2} marginBottom={0}>
        <Box marginRight={2} marginBottom={2}>
          <Typography variant="h2">Preview af {item.name}</Typography>
        </Box>

        {item.type === 'image' && (
          <Box textAlign="center">
            <img className={classes.image} src={previewUrl} alt="" />
          </Box>
        )}

        {item.type === 'movie' && (
          <Box className={classes.video}>
            <video
              height={300}
              width="100%"
              controls
              controlsList="nodownload nofullscreen noremoteplayback"
              autoPlay
              loop
              muted
              onTimeUpdate={handleVideoTimeUpdate}
            >
              <source src={previewUrl+ '?v' +Date.now()} type="video/mp4" />
            </video>
          </Box>
        )}
      </Box>

      <Box margin={2}>
        <Button
          disabled={isLoading}
          variant="outlined"
          fullWidth
          onClick={handleGenerateThumbnailClick}
        >
          {!isLoading ? (
            'Udtræk thubmail og afslut'
          ) : (
            <CircularProgress color="inherit" size={24} />
          )}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default MediaActivate;
