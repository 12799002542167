import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@material-ui/core';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import React, { useContext, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { useQueryClient } from 'react-query';
import { API_URL, QUERY_KEYS } from '../../constants/constants';
import { AuthContext, DataContext } from '../../contexts';
import { useGenerateThumbnail, useTranscodeMedia } from '@/api/useMedia';
import { useSavePlaylist } from '@/api/usePlaylist';
import { MediaItem, PlaylistFormValues } from '@/interfaces';
import { randomColor } from '@/lib/random-color';
import { delay } from '@/helpers/async-helper';

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const MediaUploadDialog: React.FC = () => {
  const filePondRef = useRef<FilePond>(null);
  const { setLastUpdatedMedia } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [generatePlaylist, setGeneratePlaylist] = useState<boolean>(true);
  const { transcodeMedia } = useTranscodeMedia();
  const { generateThumbnail } = useGenerateThumbnail();
  const { savePlaylist } = useSavePlaylist();
  const [playlistColor, setPlaylistColor] = useState<string>(randomColor());

  const handleNewColor = () => {
    setPlaylistColor(randomColor());
  };

  return (
    <Box margin={2}>
      <Box marginBottom={2}>
        <Typography variant="h3" gutterBottom>
          Upload
        </Typography>
        <Typography variant="body2">
          Du kan uploade en eller flere billed- eller videofiler ad gangen.
        </Typography>
      </Box>

      <Box width="100%" mx={1} mb={3}>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              control={<Radio size="small" />}
              label={
                <>
                  Autogenerer playliste til hvert media med farven{' '}
                  <Tooltip title="Skift farve" placement="right">
                    <Button
                      onClick={handleNewColor}
                      variant="outlined"
                      disableRipple
                      style={{
                        padding: 4,
                        minWidth: 10
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: playlistColor,
                          borderRadius: 4,
                          display: 'inline-block',
                          height: 11,
                          width: 11
                        }}
                      ></span>
                    </Button>
                  </Tooltip>
                </>
              }
              onChange={(_, value: boolean) => setGeneratePlaylist(true)}
              checked={generatePlaylist}
            />
            <FormControlLabel
              control={<Radio size="small" />}
              label="Upload kun media"
              onChange={(_, value: boolean) => setGeneratePlaylist(false)}
              checked={!generatePlaylist}
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <FilePond
        ref={filePondRef}
        allowMultiple={true}
        allowRevert={false}
        allowFileSizeValidation
        maxFileSize="200mb"
        allowFileTypeValidation
        acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg', 'video/*']}
        name="file"
        maxFiles={30}
        onprocessfiles={() => {}}
        onaddfile={() => {}}
        server={{
          process: function (
            fieldName,
            file,
            _metadata,
            onLoad,
            onError,
            onProgress,
            onAbort
          ) {
            const onComplete = (filename: string) => {
              // the load method accepts either a string (id) or an object
              onLoad(filename);

              setLastUpdatedMedia({
                name: filename
              });

              queryClient.invalidateQueries(QUERY_KEYS.MEDIA_ITEMS);
            };

            const uploadMedia = (): Promise<any> => {
              return new Promise((resolve, reject) => {
                const request = new XMLHttpRequest();
                const url = `${API_URL}upload.php?clientname=${user?.clientname}&context=media&un=${user?.username}&pw=${user?.password}&location_id=`;

                request.open('POST', url);
                request.withCredentials = true;

                // Should call the progress method to update the progress to 100% before calling load
                // Setting computable to false switches the loading indicator to infinite mode
                request.upload.onprogress = (e) => {
                  onProgress(e.lengthComputable, e.loaded, e.total);
                };

                // Should call the load method when done and pass the returned server file id
                // this server file id is then used later on when reverting or restoring a file
                // so your server knows which file to return without exposing that info to the client
                request.onload = function () {
                  let json: any = {};
                  try {
                    json = JSON.parse(request.responseText);
                  } catch (error) {
                    console.error('Filepond', error);
                  }

                  const success =
                    request.status >= 200 &&
                    request.status < 300 &&
                    !json.error;

                  if (success) {
                    resolve(json.result);
                  } else {
                    reject(
                      json.error
                        ? json.error.message + ' (' + json.error.code + ')'
                        : `Upload failed with status ${request.status}`
                    );
                  }
                };

                request.onerror = () => {
                  reject(new Error('Network error occurred during upload'));
                };

                // Handle timeout
                request.timeout = 30000; // 30 seconds
                request.ontimeout = () => {
                  reject(new Error('Upload timed out'));
                };

                try {
                  // fieldName is the name of the input field
                  // file is the actual file object to send
                  const formData = new FormData();
                  formData.append(fieldName, file, file.name);
                  formData.append('name', file.name);

                  request.send(formData);
                } catch (e) {
                  reject(e);
                }

                // Should expose an abort method so the request can be cancelled
                return {
                  abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    onAbort();
                  }
                };
              });
            };

            uploadMedia()
              .then((result) => {
                console.log('Upload successful:', result);

                if (generatePlaylist) {
                  console.log('Waiting 5 seconds before transcoding media...');

                  delay(5000).then(() => {
                    var mediaItem = {
                      name: result.filename,
                      source:
                        'http://media.spotproduction.dk/Spotproduction/' +
                        result.filename
                    } as MediaItem;
                    console.log('Transcoding media:', mediaItem);

                    transcodeMedia(mediaItem).then((transcodeResult) => {
                      const previewUrl = transcodeResult.output_url;
                      console.log('Generating thumbnail:', previewUrl);

                      generateThumbnail(mediaItem, previewUrl, 0).then(
                        (thumbResult) => {
                          console.log('Thumbnail generated:', thumbResult);

                          const playlist: PlaylistFormValues = {
                            id: null,
                            color: playlistColor.substring(1),
                            name: result.filename,
                            comment: '...',
                            content: [],
                            medias: [
                              {
                                ...mediaItem,
                                id: thumbResult.id
                              }
                            ]
                          };

                          savePlaylist(playlist).then(() => {
                            onComplete(result.filename);
                          });
                        }
                      );
                    });
                  });
                } else {
                  onComplete(result.filename);
                }
              })
              .catch((err) => {
                // Can call the error method if something is wrong
                onError(err);
                console.error('Upload error:', err);
              });
          }
        }}
      />
    </Box>
  );
};

export default MediaUploadDialog;
