import parser from 'fast-xml-parser';

const parserOptions: Partial<parser.X2jOptions> = {
  attributeNamePrefix: '',
  attrNodeName: false, //default is 'false'
  textNodeName: '$value',
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: false,
  parseNodeValue: true,
  parseAttributeValue: true,
  trimValues: true,
  parseTrueNumberOnly: true,
  arrayMode: false //"strict"
};

export const xml2json = (
  xml: string,
  textNodeName: string = '',
  options: any = {}
): any => parser.parse(xml, { ...parserOptions, ...options, textNodeName });
