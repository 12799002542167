import React, { useContext } from 'react';
import { useField } from 'formik';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  makeStyles,
  Theme
} from '@material-ui/core';
import {
  Movie as MovieIcon,
  Image as ImageIcon,
  Code as CodeIcon,
  LibraryBooks as LibraryBooksIcon
} from '@material-ui/icons';
import { LastUpdatedMedia, MediaItem, MediaItemType } from '../../interfaces';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import LazyLoad, { forceCheck } from 'react-lazyload';
import { DialogContext } from '../../contexts/DialogContext';
import MediaShow from './MediaShow';
import { DataContext } from 'contexts/DataContext';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  card: {
    background: 'none'
  },
  chip: {
    left: '50%',
    position: 'absolute',
    top: 40,
    transform: 'translateX(-50%)'
  },
  cardImage: {
    boxShadow: '#eee 0 0 2px 1px',
    height: 90,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 160
  },
  cardContent: {
    fontSize: 12,
    flexGrow: 1,
    padding: 0,
    paddingBottom: theme.spacing(1),
    width: 160,
    wordBreak: 'break-word'
  },
  cardActionArea: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  cardActions: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0
  },
  position: {
    backgroundColor: 'rgba(255, 255, 255, .75)',
    borderRadius: 4,
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 700,
    height: 24,
    left: 5,
    lineHeight: '24px',
    position: 'absolute',
    textAlign: 'center',
    top: 5,
    width: 24
  },
  text: {
    lineHeight: 1,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  }
}));

interface MediaCardProps {
  item: MediaItem;
  addButton?: boolean;
  removeButton?: boolean;
  movableButton?: boolean;
  showPosition?: boolean;
  position?: string;
  style?: React.CSSProperties;
}

const MediaCard: React.FC<MediaCardProps> = ({
  item,
  addButton = false,
  removeButton = false,
  movableButton = false,
  showPosition = false,
  position = '',
  style = {}
}) => {
  const classes = useStyles();
  const { lastUpdatedMedia, setLastUpdatedMedia } = useContext(DataContext);
  const highlight = isHighlighted(item, lastUpdatedMedia);
  const { openDialog } = useContext(DialogContext);
  const [field, , helpers] = useField<MediaItem[]>({ name: 'medias' });
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.uid });

  const cardStyle = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    ...style
  };

  const handleMediaClick = () => {
    setLastUpdatedMedia(item);
    openDialog(<MediaShow item={item} />);
  };

  return (
    <Card
      ref={setNodeRef}
      className={classes.card}
      style={cardStyle}
      {...attributes}
    >
      <Box display="flex" height="100%" flexDirection="column">
        <CardActionArea
          onClick={handleMediaClick}
          className={classes.cardActionArea}
          title={highlight ? 'Åben medie (Sidst redigeret)' : 'Åben medie'}
          style={{ backgroundColor: highlight ? '#ffeb3b' : '#fff' }}
        >
          {showPosition && <Box className={classes.position}>{position}</Box>}

          <MediaTypeIcon type={item.type} />

          {item.thumb ? (
            <LazyLoad height={100} throttle={300}>
              <CardMedia
                image={
                  highlight
                    ? item.thumb + '?v' + lastUpdatedMedia?.cacheBurst
                    : item.thumb
                }
                className={classes.cardImage}
              />
            </LazyLoad>
          ) : (
            // Image placeholder
            <Box className={classes.cardImage}></Box>
          )}

          {!item.id && (
            <Chip
              size="small"
              label="Ikke aktiveret"
              color="secondary"
              className={classes.chip}
            />
          )}
          <CardContent className={classes.cardContent}>{item.name}</CardContent>
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          {addButton && item.id && (
            <Button
              size="small"
              fullWidth
              onClick={() => {
                item.uid = uuid();
                const medias = [...field.value, item];

                helpers.setValue(medias);
                helpers.setTouched(true);
                forceCheck();
              }}
            >
              Tilføj til playlisten
            </Button>
          )}
          {removeButton && (
            <Button
              size="small"
              onClick={() => {
                const medias = [
                  ...field.value.filter((x) => x.uid !== item.uid)
                ];

                helpers.setValue(medias);
                helpers.setTouched(true);
                forceCheck();
              }}
              style={{ width: 65 }}
            >
              Fjern
            </Button>
          )}
          {movableButton && (
            <Button
              size="small"
              style={{ cursor: 'move', width: 65 }}
              {...listeners}
            >
              Flyt
            </Button>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};

const isHighlighted = (item: MediaItem, lastUpdatedMedia?: LastUpdatedMedia) =>
  (item.id && item.id === lastUpdatedMedia?.id) ||
  (!item.id && item.name === lastUpdatedMedia?.name);

interface MediaTypeIconProps {
  type: MediaItemType;
}

const MediaTypeIcon: React.FC<MediaTypeIconProps> = ({ type }) => {
  switch (type) {
    case 'image':
      return <RenderIcon icon={ImageIcon} />;
    case 'template':
      return <RenderIcon icon={LibraryBooksIcon} />;
    case 'html':
      return <RenderIcon icon={CodeIcon} />;
    case 'movie':
    case 'video_in':
    default:
      return <RenderIcon icon={MovieIcon} />;
  }
};

interface RenderIconProps {
  icon: React.FC<any>;
}

const RenderIcon: React.FC<RenderIconProps> = (props) => {
  const Icon = props.icon;
  return (
    <Box position="relative" width="100%">
      <Box position="absolute" right={5} top={5}>
        <Icon
          color="primary"
          style={{ backgroundColor: '#fff', borderRadius: 4 }}
        />
      </Box>
    </Box>
  );
};

export default MediaCard;
