import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSignIn } from '../../api/useSignIn';
import { QUERY_KEYS } from '../../constants/constants';
import {
  Button,
  Container,
  Avatar,
  Typography,
  makeStyles,
  Box,
  Chip,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { LocalizationContext, useLocalize } from 'contexts/LocalizationContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8)
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  chip: {
    backgroundColor: '#f12d2d',
    marginBottom: theme.spacing(2)
  },
  field: {
    backgroundColor: theme.palette.primary.dark
  },
  button: {
    marginTop: theme.spacing(3)
  }
}));

const SignIn: React.FC = () => {
  const { currentLanguageId, setCurrentLanguageId } =
    useContext<any>(LocalizationContext);
  const { localize } = useLocalize();
//Sample address: http://localhost:3000?un=myuser&pw=pwtest
const queryParams = new URLSearchParams(window.location.search);
const un = queryParams.get('un');
const pw = queryParams.get('pw');
  const classes = useStyles();
  const { signIn } = useSignIn();
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  }>({ username: '', password: '' });
  const initialValues = {
    username: un ? un :'',
    password: pw ? pw :''
  };
  const validationSchema = getValidationSchema();
  const { isLoading, isError, refetch } = useQuery<boolean>(
    [QUERY_KEYS.SIGN_IN],
    () => signIn(credentials.username, credentials.password),
    {
      enabled: false,
      retry: false
    }
  );

  useEffect(() => {
    if (credentials.username && credentials.password) {
      refetch();
    }
  }, [credentials, refetch]);

  return (
    <Container maxWidth="xs">
      <Box className={classes.wrapper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon htmlColor="#ffffff" />
        </Avatar>

        <Typography variant="h1" className={classes.title}>
          Spotmanager
        </Typography>

        {isError && <Chip className={classes.chip} label={localize(213)} />}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(props) =>
            setCredentials({
              username: props.username,
              password: props.password
            })
          }
        >
          <Form>
            <Field
              label={localize(210)}
              name="username"
              component={TextField}
              InputProps={{ className: classes.field }}
              disabled={isLoading}
              variant="outlined"
              margin="normal"
              autoFocus
              fullWidth
            />

            <Field
              label={localize(211)}
              name="password"
              component={TextField}
              InputProps={{ className: classes.field }}
              disabled={isLoading}
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
            />

            <FormControl>
              <RadioGroup
                row
                value={currentLanguageId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentLanguageId(parseInt(event.target.value))
                }
              >
                <FormControlLabel value={1} control={<Radio />} label="Dansk" />
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="English"
                />
              </RadioGroup>
            </FormControl>

            <Button
              className={classes.button}
              disabled={isLoading}
              color="primary"
              variant="contained"
              type="submit"
              fullWidth
            >
              {isLoading ? localize(212) : localize(209)}
            </Button>
          </Form>
        </Formik>
      </Box>
    </Container>
  );
};

const getValidationSchema = () =>
  Yup.object().shape({
    username: Yup.string().required('Indtast venligst dit brugernavn.'),
    password: Yup.string().required('Indtast venligst dit kodeord.')
  });

export default SignIn;
