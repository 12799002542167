import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { useSync } from '../../api/useSync';
import { DialogContext } from '../../contexts/DialogContext';
import { AuthContext, useLocalize } from 'contexts';
import { useQueryClient } from 'react-query';

const Sync: React.FC = () => {
  const [isSyncronizing, setIsSyncronizing] = useState(false);
  const { setUser } = useContext(AuthContext);
  const { closeDialog } = useContext(DialogContext);
  const { localize } = useLocalize();
  const queryClient = useQueryClient();
  const { sync } = useSync();

  const handleSyncClick = async () => {
    setIsSyncronizing(true);
    await sync();
    setTimeout(() => handleSignOutClick(), 1000);
  };

  const handleSignOutClick = () => {
    setUser(null);
    queryClient.clear();
    closeDialog();
  };

  return (
    <React.Fragment>
      <Box padding={3} paddingBottom={0}>
        <Typography variant="body1">
          {isSyncronizing === false && localize(289)}
          {isSyncronizing === true && localize(290)}
        </Typography>
      </Box>

      <Box margin={2}>
        <ButtonGroup fullWidth>
          <Button
            disabled={isSyncronizing}
            variant="outlined"
            fullWidth
            onClick={handleSignOutClick}
          >
            {localize(117)}
          </Button>
          <Button
            disabled={isSyncronizing}
            color="default"
            variant="outlined"
            fullWidth
            onClick={handleSyncClick}
          >
            {isSyncronizing ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              localize(116)
            )}
          </Button>
        </ButtonGroup>
      </Box>
    </React.Fragment>
  );
};

export default Sync;
