import React, { useContext } from 'react';
import LocalizationContextProvider from '../../contexts/LocalizationContext';
import { useQuery } from 'react-query';
import SignIn from '../Account/SignIn';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import { AuthContext } from '../../contexts';
import { QUERY_KEYS } from '../../constants/constants';
import Loading from '../Loading/Loading';
import { SnackbarContext } from 'components/Snackbar/Snackbar';
import { LocalizationLanguage, LocalizationPhrase } from 'interfaces';
import { useGetLocalization } from 'api/useLocalization';

function App() {
  const { user } = useContext(AuthContext);
  const { createSnack } = useContext(SnackbarContext);
  const { getLocalization } = useGetLocalization();
  const { data, isLoading, isError } = useQuery<{
    languages: LocalizationLanguage[];
    phrases: LocalizationPhrase[];
  }>(QUERY_KEYS.LOCALIZED_DATA, getLocalization);

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isError) {
    createSnack(`getLocalizedData request failed!`, { severity: 'error' });
    return null;
  }

  return (
    <LocalizationContextProvider data={data}>
      {user ? <AuthenticatedRoutes /> : <SignIn />}
    </LocalizationContextProvider>
  );
}

export default App;
