import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  List,
  Link,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  CircularProgress
} from '@material-ui/core';
import { MediaItem } from '../../interfaces';
import {
  useDeleteMedia,
  useTranscodeMedia,
  useEditMedia
} from '../../api/useMedia';
import React, { useContext, useState } from 'react';
import { DialogContext } from '../../contexts/DialogContext';
import MediaActivate from './MediaActivate';
import EditableProperty from './EditableProperty';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: 281,
    margin: 'auto',
    maxWidth: '100%',
    width: 'auto'
  }
}));

interface MediaShowProps {
  item: MediaItem;
}

const MediaShow: React.FC<MediaShowProps> = ({ item }) => {
  const classes = useStyles();
  const [name, setName] = useState<string>(item.name);
  const [duration, setDuration] = useState<number>(
    parseFloat(item?.duration?.toString() || '') || 0
  );
  const { saveMediaName, saveMediaDuration } = useEditMedia();
  const { openDialog } = useContext(DialogContext);
  const { transcodeMedia } = useTranscodeMedia();
  const { deleteMedia } = useDeleteMedia();
  const [isLoading, setIsLoading] = useState<
    undefined | 'deleting' | 'activating'
  >();

  const handleActivateClick = async () => {
    if (
      window.confirm(
        'Emnet skal have oprettet preview og thumbnail førend det kan aktiveres.\n\nKlik "OK" for at fortsætte.'
      )
    ) {
      setIsLoading('activating');
      const result = await transcodeMedia(item);
      if (result) {
        setTimeout(() => {
          openDialog(
            <MediaActivate item={item} previewUrl={result.output_url} />
          );
        }, 1000);
      }
    }
  };

  const handlePreviewClick = () => {
    openDialog(<MediaActivate item={item} previewUrl={item.movie} />);
  };

  const preventDefault = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        handleActivateClick();

  }

  const handleDeleteClick = () => {
    if (
      window.confirm(
        'Er du sikker på at du ønsker at slette dette emne?\n\nHandlingen kan ikke fortrydes.'
      )
    ) {
      setIsLoading('deleting');
      deleteMedia(item);
    }
  };

  return (
    <React.Fragment>
      <Box flex="1">
        {item.type === 'image' && item.id && item.movie && (
          <img className={classes.image} src={item.movie} alt="" />
        )}

        {item.type === 'movie' && item.id && item.movie && (
          <video
            height={281}
            width="100%"
            controls
            controlsList="nodownload noremoteplayback"
            autoPlay
            loop
            muted
          >
            <source src={item.movie} type="video/mp4" />
          </video>
        )}

        <List dense disablePadding>
          {item.id && (
            <React.Fragment>
              <ListItem>
                <ListItemText style={{ marginTop: 2, marginBottom: 2 }}
                primary="ID" secondary={item.id} />
              </ListItem>
              <Divider />
            </React.Fragment>
          )}

          <ListItem>
            <EditableProperty
              labelText="Navn"
              labelValue={name}
              itemValue={name}
              isActive={!!item?.id}
              onSave={(value: string) => saveMediaName(item, value, setName)}
            />
          </ListItem>

          <Divider />

          {item.id && (
            <React.Fragment>
              <ListItem>
                <ListItemText
                style={{ marginTop: 2, marginBottom: 2 }}
                  primary="Orientation"
                  secondary={item.orientation}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          )}

          <ListItem>
            <ListItemText
            style={{ marginTop: 2, marginBottom: 2 }}
            primary="Type" secondary={item.type} />
          </ListItem>

          <Divider />

          {item?.duration && (
            <React.Fragment>
              <ListItem>
                <EditableProperty
                  labelText="Varighed"
                  labelValue={`${duration.toFixed(2)}s`}
                  itemValue={duration.toFixed(2)}
                  isActive={!!item?.id}
                  onSave={(value: string) =>
                    saveMediaDuration(item, value, setDuration)
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          )}

          {/*
          <ListItem>
            <ListItemText
              primary="Status"
              secondary={
                item.locked
                  ? 'Låst (afvent Nikolaj)'
                  : 'Ikke låst (afvent Nikolaj)'
              }
            />
          </ListItem>

          <Divider />

          <ListItem>
            <ListItemText primary="Kategori" secondary="(afvent Nikolaj)" />
          </ListItem>

          <Divider />
          */}

          <ListItem>
            <ListItemText
            style={{ marginTop: 2, marginBottom: 2 }}
              primary="Fil"
              secondary={
                <Link
                  href={item.source}
                  target="_blank"
                  color="textPrimary"
                  underline="always"
                >
                  Download fil
                </Link>
              }
            />
          </ListItem>
        </List>
      </Box>

      <Box margin={2}>
        <ButtonGroup fullWidth>
          <Button
            disabled={!!isLoading}
            color="secondary"
            variant="outlined"
            fullWidth
            onClick={handleDeleteClick}
          >
            {isLoading === 'deleting' ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
             !item.id ? 'Slet' : 'Deaktiver'            
            )}
          </Button>
          {!item.id && (
            <Button
              disabled={!!isLoading}
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleActivateClick}
            >
              {isLoading === 'activating' ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'Aktivér'
              )}
            </Button>
          )}
          {item.id && item.movie && (
            <Button
              color="default"
              variant="outlined"
              fullWidth
              onClick={handlePreviewClick}
              onContextMenu={preventDefault}
            >
              Preview
            </Button>
          )}
        </ButtonGroup>
      </Box>
    </React.Fragment>
  );
};

export default MediaShow;
