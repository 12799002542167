// const playlist: PlaylistApiInput = {
//   playlist_id: 'null',
//   playlist_title: values.name,
//   playlist_color: values.color,
//   playlist_comment: '',
//   playlist_modified: 'true',
//   playlist_content: '1346' // <- medias?
// };
// const formData = new FormData();
// for (const key in playlist) {
//   formData.append(key, playlist[key as keyof PlaylistApiInput].toString());
// }

export const objToFormData = (obj: any) => {
  const formData = new FormData();
  for (const key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
};
