import React from 'react';
import { useParams } from 'react-router-dom';
import PlaylistForm from './PlaylistForm/PlaylistForm';
import { MediaItem, Playlist, PlaylistFormValues } from '../../interfaces';
import { RenderSnack } from '../Snackbar/Snackbar';
import { useGetPlaylist } from '../../api/usePlaylist';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../constants/constants';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useGetMediaItems } from 'api/useMedia';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    height: 'calc(100vh - 350px)',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
});

interface PlaylistEditProps {
  copy: boolean;
}

const PlaylistEdit: React.FC<PlaylistEditProps> = ({ copy = false }) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { getPlaylist } = useGetPlaylist();
  const { getMediaItems } = useGetMediaItems();
  const {
    data: playlist,
    isLoading: isPlaylistLoading,
    isError: isPlaylistError
  } = useQuery<Playlist>([QUERY_KEYS.PLAYLISTS, id], () => getPlaylist(id));

  const {
    data: medias,
    isLoading: isMediaLoading,
    isError: isMediaError
  } = useQuery<MediaItem[]>(QUERY_KEYS.MEDIA_ITEMS, getMediaItems);

  if (isPlaylistLoading || isMediaLoading) {
    return (
      <Box className={classes.wrapper}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (isPlaylistError || isMediaError || !playlist) {
    return (
      <Box className={classes.wrapper}>
        <RenderSnack
          id={0}
          message={`Problem loading playlist (${id}).`}
          open={true}
          options={{ severity: 'error' }}
        />
      </Box>
    );
  }

  const mediasWithUid =
    playlist?.item?.map((item) => {
      const media = medias?.find((x) => x.id === item.id)!;
      return {
        ...media,
        uid: uuid()
      };
    }) || [];

  const formValues: PlaylistFormValues = {
    id: copy ? null : playlist.id,
    color: playlist.color,
    name: copy ? `${playlist.name} - copy` : playlist.name,
    comment: playlist.comment,
    content: [],
    medias: mediasWithUid
  };

  return <PlaylistForm formValues={formValues} />;
};

export default PlaylistEdit;
