import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { API_URL } from '../constants/constants';

export const useFetcher = () => {
  const { setUser } = useContext(AuthContext);

  const fetcher = async (url: string, options?: RequestInit) => {
    const response = await fetch(API_URL + url, {
      credentials: 'include',
      ...options
    });

    if (response.status === 401 || response.status === 403) {
      // signout
      setUser(null);
      console.error('fetcher === 401||403', response);
      // throw new Error(response.statusText);
    }

    if (response.status !== 200) {
      // error
      console.error('fetcher !== 200', response);
      throw new Error(response.statusText);
    }

    return await response.text();
  };

  return { fetcher };
};
