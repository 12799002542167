import App from './components/App/App';
//import reportWebVitals from './reportWebVitals';
import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import daLocale from 'date-fns/locale/da';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SnackProvider } from './components/Snackbar/Snackbar';
import AuthContextProvider from './contexts/AuthContext';
import DialogContextProvider from './contexts/DialogContext';
import { createTheme } from './theme/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount) => failureCount < 2,
      staleTime: 60 * 1000 // 1 minute
    }
  }
});
const theme = createTheme();

export default function Root() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <SnackProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={daLocale}>
              <DialogContextProvider>
                <App />
              </DialogContextProvider>
            </MuiPickersUtilsProvider>
          </SnackProvider>
        </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}
